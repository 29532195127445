@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-button {
  @apply hidden;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300;
}

.form__error {
  @apply text-sm text-red-700;
}

.form__control {
  @apply border-dms-light-grey text-dms-dark-green relative flex h-10 w-full items-center overflow-hidden rounded border px-2 pb-0.5 focus-within:ring focus:outline-none focus:ring;
}
.form__control input {
  @apply -mx-2 h-full flex-1 pb-0.5 pl-2 pr-8 focus:outline-none;
}
.form__control::placeholder,
.form__control input::placeholder {
  @apply text-sm text-gray-500;
}

select.form__control {
  @apply appearance-none pb-0 text-sm;
}

.form__label {
  @apply text-dms-dark-green text-sm;
}
